<template>
    <div>
      <b-card border-variant="danger" v-if="errore_caricamento">
        <b-row class="match-height">
          <b-col
            md="8"
          >
            <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
            <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
          </b-col>
          <b-col 
            md="4"
            class="text-center"
          >
            <b-button
            variant="primary"
            :to="{ name: 'area-clienti-dashboard' }"
            >
                <feather-icon
                    icon="CornerUpLeftIcon"
                    size="16"
                    class="mr-1"
                />Home
            </b-button>
          </b-col>
        </b-row>
          
      </b-card>
          
      <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner  v-if="errore_caricamento == false"
            variant="primary"
            style="width: 3rem; height: 3rem;"
            label="loading"
        />
        <div class="pt-1 text-primary" style="display:block;">Caricamento</div>
      </div>
  
      <b-card bg-variant="Default" v-if="Caricato">
        
        <div class="text-right">
          <b-button variant="outline-primary" class="px-1 mb-1" @click.prevent="clickReset" v-if="resetState != 'hidden'"><feather-icon icon="DeleteIcon" /></b-button>
        </div>
  
          <!-- table -->
          <vue-good-table
          mode="remote"
          ref="table"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :totalRows="totalRecords"
          :rows="rows"
          :columns="columns"
          :isLoading=isLoading
          :search-options="{ 
              enabled: true,
              externalQuery: searchTerm }"
          :pagination-options="{
              enabled: true,
              perPage:pageLength
          }"
          >
          
          <template slot="loadingContent">
            <div class="text-center my-3">
                <b-spinner
                  variant="primary"
                  style="width: 3rem; height: 3rem;"
                  label="loading"
                />
                <div class="d-block pt-1 text-primary">Caricamento</div>
            </div>
          </template>
          
          <div slot="emptystate"> <div class="text-center py-4"> Nessun dato disponibile - (Messaggio personalizzabile) </div> </div>
  
          <template
              slot="table-row"
              slot-scope="props"
          >
  
                <span v-if="props.column.field === 'totale'">
                {{ prezzoFormattato(props.formattedRow[props.column.field]) }}
                </span>

                <span v-else-if="props.column.field === 'stato'">
                <b-badge :variant="statoVariant(props.row.stato)">
                    {{ props.row.stato }}
                    </b-badge>
                </span>

                <span v-else-if="props.column.field === 'license.ref_code'">
                    <a @click="$router.push({ name: 'area-clienti-licenze-show', params: { id_riga: props.row.license.id }})">{{ props.formattedRow[props.column.field] }}</a>
                </span>

                <span v-else-if="props.column.field === 'url_pdf'">
                    <b-button variant="outline-primary" size="sm" target="_blank" :href="props.row.url_pdf">Scarica pdf</b-button>
                </span>
  
                <!-- Column: standard -->
                <span v-else>
                {{ props.formattedRow[props.column.field] }}
                </span>
          </template>
  
          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
              <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                  Visualizzazione da 1 a 
                  </span>
                  <b-form-select
                  v-model="pageLength"
                  :options="['10','25','50','100']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> di {{ props.total }} righe </span>
              </div>
              <div>
                  <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                  <template #prev-text>
                      <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      />
                  </template>
                  <template #next-text>
                      <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                      />
                  </template>
                  </b-pagination>
              </div>
              </div>
          </template>
          </vue-good-table>
              
      </b-card>

    </div>
  </template>
  
  <script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton,  VBToggle, VBTooltip
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import Ripple from 'vue-ripple-directive'
  import { prezzoFormattato } from '@core/utils/funzioniGlobali' 
  
  export default {
    components: {
      BCardCode,
      VueGoodTable,
      BCard, 
      BCardText,
      BRow, 
      BCol, 
      BBadge,
      BPagination,
      BSpinner,
      BInputGroup,
      BFormGroup,
      BFormInput,
      BInputGroupPrepend,
      BInputGroupAppend,
      BDropdownDivider,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      BButton,
    },
    setup() {
        return {
          prezzoFormattato,
        }
    },
    directives: {
      'b-toggle': VBToggle,
      'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        isLoading: true,
        emptystate: true, 
        pageLength: 10,
        dir: false,
        columns: [
          {
            label: 'Stato',
            field: 'stato',
            width: '160px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
              filterDropdownItems: [
                { text: "In Pagamento", value: "In Pagamento" },
                { text: "Pagata", value: "Pagata" }
              ],
            },
          },
          {
            label: 'Totale',
            field: 'totale',
            width: '120px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Numero',
            field: 'numero',
            width: '120px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra',
            },
          },
          {
            label: 'Data',
            field: 'data',
            width: '120px',
            filterOptions: {
              enabled: false, 
            },
          },
          {
            label: 'Licenza',
            field: 'license.ref_code',
            width: '110px',
            filterOptions: {
              enabled: true,
              placeholder: 'Filtra', 
            },
          },
          {
            label: 'Scarica copia di cortesia',
            field: 'url_pdf',
            filterOptions: {
              enabled: false,
            },
          },
        ],
        rows: [],
        searchTerm: '',
        resetState: 'hidden',
        totalRecords: 0,
        serverParams: {
          columnFilters: {},
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        startServerParams: {
          columnFilters: {},
          sort: [
              {
              field: 'id',
              type: 'desc'
              }
          ],
          page: 1, 
          perPage: 10
        },
        listSourceOption: [ ],
        listStatusOption: [ ],
        Caricato: false,
        errore_caricamento: false,
      }
    },
  computed: {
    statoVariant() {
      const statusColor = {
        'Da Emettere'    : 'light-info',
        'Da Pagare' : 'light-danger',
        'In Pagamento' : 'light-warning',
        'Pagata' : 'light-success',
        'Insoluta' : 'light-danger',
        'Stornata' : 'light-secondary',
      }

      return status => statusColor[status]
    },
  },
    mounted() {
      //in caso di resize del browser -> ricalcolo della larghezza della finestra
      window.onresize = () => {
        this.checkWindowsSizeAndResponsiveGoodTable();
      }
    },
    created() {
      this.checkWindowsSizeAndResponsiveGoodTable();
      
      //adesso faccio il caricamento dei dati
      this.loadItems();
  
      this.Caricato = true;
      this.errore_caricamento = false;
    },
    methods: {
      checkWindowsSizeAndResponsiveGoodTable(){
        if(window.innerWidth <1000){
          this.$set(this.columns[3], 'hidden', true)
          this.$set(this.columns[4], 'hidden', true)
          this.$set(this.columns[5], 'hidden', true)
        } else if(window.innerWidth <1200){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
          this.$set(this.columns[5], 'hidden', true)
        } else if(window.innerWidth <1400){
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
          this.$set(this.columns[5], 'hidden', false)
        } else {
          //tutte le colonne visualizzate
          this.$set(this.columns[3], 'hidden', false)
          this.$set(this.columns[4], 'hidden', false)
          this.$set(this.columns[5], 'hidden', false)
        }
      },
      clickReset(){
        console.log("clickReset");
  
        //forza reset filtro colonne
        this.$refs.table.reset();
  
        //forza reset parametri iniziali della tabella
        this.updateParams(this.startServerParams);
        this.loadItems();
        
        //disattiva bottone "reset"
        this.resetState = 'hidden';
      },
      advanceFilter(val) {
        //this.searchTerm = val
  
        this.updateParams(val);
        this.loadItems();
      },
      
      updateParams(newProps) {
        //console.log(newProps)

        //attiva bottone "reset" filtro o ricerca
        this.resetState = 'attiva';
  
        this.serverParams = Object.assign({}, this.serverParams, newProps);
      },
      
      onPageChange(params) {
        this.updateParams({page: params.currentPage});
        this.loadItems();
      },
  
      onPerPageChange(params) {
        this.updateParams({perPage: params.currentPerPage});
        this.loadItems();
      },
  
      onSortChange(params) {
        //console.log('cambio ordinamento')
        //console.log(params)
  
        let field_ricerca = '';
  
        //colonne con relazione
        if(params[0].field == 'source.text'){
          field_ricerca = 'source.name';
        } else if(params[0].field == 'aggregato_name_surname'){
          field_ricerca = 'user.surname';
        } else {
          field_ricerca = params[0].field;
        }
  
        this.updateParams({
          sort: [{
            type: params[0].type,
            field: field_ricerca,
          }],
        });
        this.loadItems();
      },
      
      onColumnFilter(params) {
        //console.log("AGGIORNAMENTO FILTRO COLONNA --------")
        this.updateParams(params);
        this.loadItems();
      },
  
      loadItems() {
        this.isLoading = true;
  
        if (typeof this.serverParams.columnFilters.aggregato_name_surname !== 'undefined'){
          var temporaneo_aggregato_name_surname = this.serverParams.columnFilters.aggregato_name_surname;
          delete(this.serverParams.columnFilters.aggregato_name_surname);
        }
  
        this.$http.get('api/customer/fatture/list', {
          params: this.serverParams 
          }).then(response => { 
              if(response.data.statusCode == 200){
                //ok
                this.totalRecords = response.data.reply.meta.total;
                this.rows = response.data.reply.data;
                
              } else if(response.data.statusCode == 422){
                //errori con testo di notifica ok dall'api
                this.$swal({
                  title: 'Errore',
                  text: 'codice errore: '+response.data.reply,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              } else {
                //tutto gli altri possibili errori
                this.$swal({
                  title: 'Errore Generico',
                  text: 'codice errore: '+response.data.statusCode,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    textButton: 'Chiudi',
                  },
                  buttonsStyling: false,
                })
              }
              
              //rimuovi il loading dopo il caricamento dei dati nella tabella
              this.isLoading = false;
          
          })
  
      },
    }
  }
  </script>
  
  <style lang="scss" >
  @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>